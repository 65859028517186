import { version } from './version';

export const environment = {
  BASE_URL:
    'https://dtemuriae.giss.com.br/PlatformaDTe',
  production: true,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  firebaseConfig: {
    apiKey: "AIzaSyCfkugEPINGr9sHKTa0anJq1HUkiNCRvbg",
    authDomain: "dte-id.firebaseapp.com",
    projectId: "dte-id",
    storageBucket: "dte-id.appspot.com",
    messagingSenderId: "619843953278",
    appId: "1:619843953278:web:7251e3a12840932a8b2e2f",
    measurementId: "G-ST0XXRQ7HP"
  },
  notificationApi: "https://dtealerta.eiconbrasil.com.br/pushSubscription/registrar",
  powerbi: '3143906',
  schema: 'pbi_dte_muriae',
  groupId: 'a869377c-cdb9-40a9-a57b-4d50a27784c3',
  analiseGeral: '140a1dbb-6c38-4d3a-9c41-e98d15f5f5c9',
  analiseUsuarios: '4c863b57-1d39-48dd-b63b-22dfde379c0b',
  analiseComunicados: '93846b72-9513-4b51-8a28-0204cafc4811',
  analiseProcurador: '5eab736e-336b-4f24-a3ea-a340b4d96101',
  analiseEventos: 'be1550e5-7955-4a50-beb7-0bc5fa4122de'
};
